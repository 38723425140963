import React, { useEffect, useState } from "react";
import cx from "classnames";
import { Header } from "./components/Header/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Theme } from "./Store";
import { Home } from "./pages/Home";
import { Lazy } from "./components/Lazy/Lazy";
import { NavAnim } from "./components/NavAnim/NavAnim";
import { NoMatch } from "./pages/NoMatch";
import { useRecoilValue } from "recoil";
import { Tips } from "./pages/Tips/Tips";
import { themeState, viewState } from "./state/uiState";
import "./ui/reset.scss";
import "./ui/typography.scss";
import "./App.scss";
import { Quiz } from "./pages/Quiz/Quiz";

const App = () => {
  const theme = useRecoilValue(themeState);
  const view = useRecoilValue(viewState);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 30);
  }, []);

  return (
    <Router>
      <div
        className={cx("app", "average", view, {
          "intro-animation": false,
          "dark-mode": theme === Theme.DARK,
          loaded: loaded,
        })}
      >
        <div id="curtain" />

        <NavAnim />

        <Header />

        <Switch>
          <Route path="/generator" exact>
            <Lazy
              load={() =>
                import(
                  /* webpackChunkName: "generator" */ "./pages/Maps/GeneratorPage"
                )
              }
              name="GeneratorPage"
            />
          </Route>

          <Route path="/sweden" exact>
            <Lazy
              load={() =>
                import(
                  /* webpackChunkName: "sweden" */ "./pages/Maps/Sweden/SwedenPage"
                )
              }
              name="SwedenPage"
            />
          </Route>

          <Route path="/cv" exact>
            <Lazy
              load={() =>
                import(
                  /* webpackChunkName: "merits" */ "./pages/Merits/MeritsPage"
                )
              }
              name="MeritsPage"
            />
          </Route>

          <Route path="/graphs" exact>
            <Lazy
              load={() =>
                import(/* webpackChunkName: "graphs" */ "./pages/Graphs/Graphs")
              }
              name="Graphs"
            />
          </Route>
          <Route path="/components">
            <Lazy
              load={() =>
                import(
                  /* webpackChunkName: "components" */ "./pages/Components/Components"
                )
              }
              name="Components"
            />
          </Route>
          <Route path="/invoice" exact>
            <Lazy
              load={() =>
                import(
                  /* webpackChunkName: "invoice" */ "./pages/Invoice/Invoice"
                )
              }
              name="Invoice"
            />
          </Route>
          <Route path="/maps" exact>
            <Lazy
              load={() =>
                import(
                  /* webpackChunkName: "map-demo" */ "./pages/Components/MapDemo"
                )
              }
              name="MapDemo"
            />
          </Route>

          <Route path="/tips" exact>
            <Tips />
          </Route>

          <Route path="/quiz" exact>
            <Quiz />
          </Route>

          <Route path="/" exact>
            <Home />
          </Route>
          <Route>
            <NoMatch />
          </Route>
        </Switch>

        {/* <LogoAnimation /> */}
      </div>
    </Router>
  );
};

export default App;
