import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { RecoilRoot } from "recoil";

(window.average || {}).importScript = (url: string) => {
  return new Promise((resolve, reject) => {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.addEventListener("load", () => resolve(script), false);
    script.addEventListener("error", () => reject(script), false);
    document.body.appendChild(script);
  });
};

ReactDOM.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>,
  document.getElementById("root"),
  () => {
    import(
      /* webpackChunkName: "components" */ "./pages/Components/Components"
    ).then((data: any) => {
      const C = data.Components;
      window.average.loaded.Components = <C />;
    });

    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker.register("/sw.js");
      });
    }
  }
);
