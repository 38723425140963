import React, { ReactNode, } from 'react';
import './Triangle.scss';

interface Props {
  children: ReactNode,
};

export const Triangle:React.FC<Props> = ({children}) => (
  <div className="animated-bg">
    <svg preserveAspectRatio="none" viewBox="0 0 200 300">
      <path d="M198 232 L100 298 L2 232 L100 0Z"/>
      <path d="M198 232 L100 298 L2 232 L100 0Z" />
      <path d="M198 232 L100 298 L2 232 L100 0Z" />
    </svg>
    {children}
  </div>
);