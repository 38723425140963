import React from "react";
import Testamonials from "../components/Testamonials/Testamonials";
import { Top } from "../components/Top/Top";
import { Attributes } from "../components/Attributes/Attributes";
import "./Home.scss";
import { Footer } from "../components/Footer/Footer";
import { Page } from "./Page";
import { Works } from "../components/Works/Works";

export function Home() {
  return (
    <Page className="home">
      <section>
        <article>
          <Top />
        </article>
      </section>

      <section className="split banner">
        <div>
          <article className="banner-do">
            <h2>What we do</h2>
            <p>We create digital experiences and visual journe...</p>
            <p>
              ...eh, no. No.
              <br />
              Web pages, we make them. Average ones.
            </p>
          </article>
          <article className="banner-know">
            <h2>What we know</h2>
            <Attributes />
          </article>
        </div>
      </section>

      <section>
        <article className="mobile-banner-know">
          <h2>What we know</h2>
          <Attributes />
        </article>
      </section>

      <section>
        <article className="works">
          <h2>Works</h2>
          <Works />
        </article>
      </section>

      <section className="testamonials">
        <article>
          <h2>What they say</h2>
          <Testamonials />
        </article>
      </section>

      {/* <article>
        <div>
          Ey! Reset that average intro again, okey?
          <FlipButton className="secondary" onClick={startIntro}>Sure</FlipButton>
        </div>
      </article> */}
      {/* <section className="split">

        <article>
          Toggle theme
          <FlipButton className="secondary" onClick={setTheme}>Sure</FlipButton>
        </article>
      </section> */}

      <Footer />
    </Page>
  );
}
