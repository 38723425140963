import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { firebase } from "./initFirebase";
import { FlipButton } from "../../components/Interactions/Button/FlipButton";
import "./Posts.scss";

const db = firebase.database();

interface Post {
  name: string;
  message: string;
  time: number;
}

function getTime(time: number) {
  const date = new Date(time);
  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);

  if (date > startOfDay) {
    const arr = date.toLocaleTimeString("sv-SE").split(":");
    arr.pop();
    return `idag ${arr.join(":")}`;
  }

  return date.toLocaleDateString();
}

export const Posts: React.FunctionComponent = () => {
  const [name, setName] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [posts, setPosts] = useState<Post[]>([]);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    let ref = db.ref("posts");
    ref.on("value", (snapshot) => {
      const state = snapshot.val();
      const newPosts: Post[] = [];
      for (const id in state) {
        newPosts.push(state[id]);
      }

      newPosts.sort((a, b) => {
        return a.time < b.time ? 1 : -1;
      });
      setPosts(newPosts);
    });
  }, []);

  const onNameChange = useCallback((e) => {
    setError(false);
    setName(e.target.value);
  }, []);
  const onMessageChange = useCallback((e) => {
    setError(false);
    setMessage(e.target.value);
  }, []);

  return (
    <>
      <div
        className={cx("posts", {
          "has-error": error,
        })}
      >
        <form>
          <div>
            <div className="input-label">Namn:</div>
            <input
              className="input-name"
              type="text"
              onChange={onNameChange}
              value={name}
            />
          </div>

          <div>
            <div className="input-label">Meddelande:</div>
            <textarea
              className="input-message"
              onChange={onMessageChange}
              value={message}
            />
          </div>

          <div className="post-error">
            <b>Namn</b> och <b>Meddelande</b>, tack.
          </div>

          {/* <button type="submit">Skicka</button> */}
          <FlipButton
            onClick={() => {
              if (!message || !name) {
                setError(true);
                return;
              }

              const postRef = db.ref("posts");
              const newPostRef = postRef.push();
              newPostRef
                .set(
                  {
                    name,
                    message,
                    time: new Date().getTime(),
                  },
                  () => setMessage("")
                )
                .catch((err) => console.log("err", err));
            }}
          >
            Skicka
          </FlipButton>
        </form>

        {/* <div className="video-wrapper">
        <iframe
          src="https://www.youtube.com/embed/bzUiA9tjn6E"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div> */}

        <ul>
          {posts.map((post) => (
            <li key={post.time} className="post">
              <div className="name-and-time">
                <div className="post-name">{post.name}</div>
                <div className="post-date">{getTime(post.time)}</div>
              </div>
              <div className="post-body">{post.message}</div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
