import React from "react";
import cx from "classnames";
import { Subject } from "./Quiz";
import "./Quiz.module.scss";

interface Props {
  numberOfQuestion: number;
  subQuestions: number | string[];
  subject: Subject;
}

const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");

export const Question: React.FunctionComponent<Props> = ({
  numberOfQuestion,
  subQuestions,
  subject,
}) => {
  return (
    <div className="paper">
      <span className="paper-circle">{numberOfQuestion}</span>
      <span className="paper-subject">
        <i>{subject}</i>
      </span>

      <ul>
        {(Array.isArray(subQuestions)
          ? subQuestions
          : [...Array(subQuestions)]
        ).map((_, i) => {
          if (typeof _ === "string") {
            return (
              <li key={i} className="paper-item">
                <div className="paper-index">{_}</div>
                <div className="paper-answer" />
              </li>
            );
          }

          return (
            <li key={i} className={cx("paper-item", "paper-dot")}>
              <div className="paper-index">{alphabet[i]}.</div>
              <div className="paper-answer" />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
