import React from "react";
import { StatusButton, StatusButtonProps } from "./StatusButton";
import { Status } from "../InputDataTypes";

function FlipButton(props: StatusButtonProps) {
  return <StatusButton {...props} flip={true} />;
}

FlipButton.defaultProps = {
  inputStatus: {
    status: Status.DEFAULT,
  },
};

export { FlipButton };
