import React, { useRef } from "react";
import { useCallback } from "react";
import "./Bubbles.scss";

const colorArray = ["1d5db8", "72018d", "17d993"];

function hexToRgb(hex: string) {
  var bigint = parseInt(hex, 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;
  return r + "," + g + "," + b;
}

function generatePoint(width: number, height: number) {
  const dx = getRandomInt(0, width);
  const dy = getRandomInt(0, height);
  const growthVelocity = 0.1 + getRandomInt(-1, 1) / 50;
  return {
    dx,
    dy,
    startY: dy,
    opacity: 1,
    r: 0,
    growthVelocity,
    max: getRandomInt(4, 30),
    color: hexToRgb(colorArray[getRandomInt(0, 2)]),
  };
}

function getOutline(width: number, height: number, offset: number) {
  const bg = new Path2D();
  bg.moveTo(2 + offset, offset + (height * 232) / 300);
  bg.lineTo(width / 2 + offset, offset + 0);
  bg.lineTo(width - 2 + offset, offset + (232 * height) / 300);
  bg.lineTo(width / 2 + offset, offset + (298 * height) / 300);
  bg.closePath();
  return bg;
}

function drawLine(
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number,
  bridge: number,
  offset: number
) {
  ctx.beginPath();
  ctx.strokeStyle = "#222";
  ctx.moveTo(2 + offset, offset + (height * 232) / 300);
  ctx.lineTo(width / 2 + offset, offset + (bridge * height) / 300);
  ctx.lineTo(width - 2 + offset, offset + (height * 232) / 300);
  ctx.stroke();
}

interface Point {
  dx: number;
  dy: number;
  startY: number;
  r: number;
  max: number;
  opacity: number;
  color: string;
  growthVelocity: number;
}

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
}

export const Bubbles: React.FunctionComponent = () => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const points = useRef<Point[]>([]);

  const draw = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    const box = canvas.getBoundingClientRect();
    canvas.width = box.width;
    canvas.height = box.height;
    ctx.clearRect(0, 0, box.width, box.height);
    const offset = 40;
    const width = box.width - offset * 2;
    const height = box.height - offset * 2;
    const frame = getOutline(width, height, offset);
    ctx.fillStyle = "#fff";
    ctx.fill(frame);
    drawLine(ctx, width, height, 0, offset);
    drawLine(ctx, width, height, 83, offset);
    drawLine(ctx, width, height, 166, offset);
    drawLine(ctx, width, height, 298, offset);

    let i = 0;
    let len = 300;
    for (i = 0; i < len; i++) {
      let point = points.current[i];

      if (point) {
        const radius = point.r;
        if (radius > point.max) {
          point = generatePoint(box.width, box.height);
          points.current[i] = point;
        }
      } else {
        point = generatePoint(box.width, box.height);
        points.current[i] = point;
      }

      ctx.beginPath();
      point.r += point.growthVelocity;
      point.dy -= point.growthVelocity;

      ctx.arc(point.dx, point.dy, point.r, 0, Math.PI * 2, true);
      if (ctx.isPointInPath(frame, point.dx, point.startY)) {
        ctx.fillStyle = `rgba(${point.color},${1 - point.r / point.max})`;
      } else {
        ctx.fillStyle = "rgba(0, 0, 0, 0)";
      }
      ctx.fill();
    }

    window.requestAnimationFrame(draw);
  }, []);

  React.useEffect(draw, [draw]);

  return (
    <div className="bubbles-logo">
      <canvas ref={canvasRef} />
    </div>
  );
};
