import firebase from "firebase/app";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBjDH4kfXAfD2jR8GlTcZ_JGHUYpvsjaBg",
  authDomain: "los-celestes.firebaseapp.com",
  databaseURL:
    "https://los-celestes-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "los-celestes",
  storageBucket: "los-celestes.appspot.com",
  messagingSenderId: "509685365683",
  appId: "1:509685365683:web:f9cfac374a4fcbbbc529c6",
};

function initFirebase() {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
}

initFirebase();

export { firebase };
