import React from "react";
import cx from "classnames";
import gameDays from "./games.json";
import { Row } from "./Row";
import { useInView } from "react-intersection-observer";
import "./Games.scss";

// one two

export enum Player {
  STUMPAN,
  KNOX,
  ZEGE,
  KAKEL,
  DONNIE,
  CONSI,
}

// prettier-ignore
const tips = [
  ["1", "-", "1", "1", "1", "1"],

  ["2", "-", "x", "1", "2", "x"], // 15 juni
  ["1", "-", "x", "x", "1", "1"],
  ["1", "-", "1", "1", "1", "1"],

  ["2", "-", "2", "2", "x", "2"], // 16 juni
  ["2", "-", "2", "2", "x", "x"],
  ["2", "-", "2", "2", "x", "2"],

  ["1", "-", "x", "2", "x", "x"], // 17 juni
  ["1", "-", "1", "1", "1", "1"],
  ["2", "-", "2", "2", "2", "2"],

  ["1", "-", "1", "1", "1", "1"], // 18 juni
  ["1", "-", "1", "1", "x", "1"],

  ["x", "-", "1", "1", "x", "1"], // 19 juni
  ["1", "-", "1", "1", "1", "1"],
  ["x", "-", "x", "x", "2", "x"],

  ["x", "-", "2", "2", "2", "x"], // 20 juni
  ["2", "-", "2", "2", "2", "x"],
  ["1", "-", "2", "1", "x", "x"],

  ["x", "-", "2", "1", "x", "x"], // 21 juni
  ["x", "-", "x", "x", "2", "2"],
  ["1", "-", "2", "2", "1", "x"],

  ["2", "-", "1", "2", "2", "2"], // 22 juni
  ["2", "-", "2", "2", "2", "2"],
  ["1", "-", "1", "1", "1", "1"],

  ["2", "-", "2", "2", "2", "2"], // 23 juni
  ["x", "-", "2", "2", "1", "2"],

  ["x", "-", "x", "x", "2", "2"], // 24 juni
  ["2", "-", "2", "2", "2", "2"],

  ["1", "-", "1", "1", "x", "2"], // 25 juni
  ["1", "-", "1", "1", "1", "1"],
  ["1", "-", "1", "1", "1", "1"],
  ["x", "-", "x", "x", "x", "1"],

  ["2", "-", "x", "x", "1", "2"], // 26 juni
  ["2", "-", "2", "2", "2", "x"],
  ["2", "-", "2", "2", "2", "2"],
  ["2", "-", "1", "1", "x", "2"],
];

const result: string[] = [
  "1",
  "2",
  "1",
  "1",
  "2",
  "x",
  "2",
  "1",
  "2",
  "2",
  "1",
  "1",
  "x",
  "1",
  "x",
  "x",
  "x",
  "1",
  "2",
  "2",
  "x",
  "x",
  "2",
  "1",
  "x",
  "2",
  "x",
  "2",
  "2",
  "x",
  "x",
  "x",
  "x",
  "x",
  "1",
  "2",
];

export const Games: React.FunctionComponent = () => {
  // const [show, setShow] = useState<boolean>(Store.getValue("showTipsTable"));
  let matchId = -1;

  // const onClick = useCallback(() => {
  //   setShow(!show);
  //   Store.setValue("showTipsTable", !show);
  // }, [show]);

  const [ref, inView] = useInView({
    threshold: 0.2,
  });

  return (
    <div
      className={cx("games", {
        "show-table": true, // show
        "show-header": inView,
      })}
    >
      {/* <div className="button-wrapper"> */}
      {/* <button onClick={onClick}>
          {show ? "Dölj tabell" : "Visa tabell"}
        </button> */}

      {/* <FlipButton onClick={onClick}>
        {show ? "Dölj tabell" : "Visa tabell"}
      </FlipButton> */}
      {/* </div> */}
      <div className="games-top-wrapper">
        <div className="games-top">
          <div className="date-and-name" />

          <div className="games-header">
            <div className="header-names">
              <div className="header-name stumpan">
                <div>Stumpan</div>
              </div>
              <div className="header-name knox">
                <div>Knox</div>
              </div>
              <div className="header-name zege">
                <div>Zege</div>
              </div>
              <div className="header-name kakel">
                <div>Kakel</div>
              </div>
              <div className="header-name donnie">
                <div>Donnie</div>
              </div>
              <div className="header-name consi">
                <div>Consi</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ul ref={ref}>
        <li className="header-rows">
          <div className="header-cell date-and-name">
            <span />
          </div>
          <div className="header-cell game-cell">
            <span>Stumpan</span>
          </div>
          <div className="header-cell game-cell">
            <span>Knox</span>
          </div>
          <div className="header-cell game-cell">
            <span>Zege</span>
          </div>
          <div className="header-cell game-cell">
            <span>Kakel</span>
          </div>
          <div className="header-cell game-cell">
            <span>Donnie</span>
          </div>
          <div className="header-cell game-cell">
            <span>Consi</span>
          </div>
        </li>

        {gameDays.map((gameDay) =>
          gameDay.games.map((game) => {
            matchId++;
            return (
              <Row
                key={game.game}
                game={game}
                dateString={gameDay.dateString}
                tips={tips[matchId]}
                result={result[matchId]}
              />
            );
          })
        )}

        {/* <li className="games-single-question exodus">
          <div className="date-and-name">
            Exodus <b>(2p)</b>
          </div>
          <div className="value-wrapper">
            <div className="value-name stumpan">
              <div>Grupp</div>
            </div>
            <div className="value-name knox">
              <div>Kvart</div>
            </div>
            <div className="value-name zege">
              <div>Semi</div>
            </div>
            <div className="value-name kakel">
              <div>Åttondel</div>
            </div>
            <div className="value-name donnie">
              <div>Kvart</div>
            </div>
            <div className="value-name fryczek">
              <div>Semi</div>
            </div>
            <div className="value-name consi">
              <div>Åttondel</div>
            </div>
          </div>
        </li> */}

        <li className="games-single-question exodus">
          <div className="date-and-name">
            Totalt antal mål <b>(2p)</b>
          </div>
          <div className="value-wrapper">
            <div className="value-name stumpan ">
              <div>133</div>
            </div>
            <div className="value-name knox ">
              <div>-</div>
            </div>
            <div className="value-name zege ">
              <div>135</div>
            </div>
            <div className="value-name kakel ">
              <div>137</div>
            </div>
            <div className="value-name donnie ">
              <div>117</div>
            </div>
            <div className="value-name consi ">
              <div>130</div>
            </div>
            {/* <div className="value-name consi ">
              <div>Zege</div>
            </div> */}
          </div>
        </li>

        <li className="games-single-question">
          <div className="date-and-name">
            Striker <b>(3p)</b>
          </div>
          <div className="value-wrapper">
            <div className="value-name stumpan ">
              <div>João Félix</div>
            </div>
            <div className="value-name knox ">
              <div>-</div>
            </div>
            <div className="value-name zege ">
              <div>Scamacca</div>
            </div>
            <div className="value-name kakel ">
              <div>Bellingham</div>
            </div>
            <div className="value-name donnie ">
              <div>Fullkrug</div>
            </div>
            <div className="value-name consi ">
              <div>Mbappé</div>
            </div>
          </div>
        </li>

        <li className="games-countries">
          <div className="date-and-name">
            Vinnare <b>(3p)</b>
          </div>
          <div className="game-cell flag stumpan ">🇵🇹</div>
          <div className="game-cell flag knox ">🇸🇪</div>
          <div className="game-cell flag zege">🇮🇹</div>
          {/* <div className="game-cell flag zege correct">🇦🇷</div> */}
          <div className="game-cell flag kakel ">🏴󠁧󠁢󠁥󠁮󠁧󠁿</div>
          <div className="game-cell flag donnie ">🇩🇪</div>
          <div className="game-cell flag consi ">🇫🇷</div>
          {/* <div className="game-cell flag consi incorrect">🇧🇷</div> */}
        </li>

        <li className="games-single-question total">
          <div className="date-and-name">Poäng</div>
          <div className="value-wrapper">
            <div className="value-name stumpan">21</div>
            <div className="value-name knox">-</div>
            <div className="value-name zege">17</div>
            <div className="value-name kakel">17</div>
            <div className="value-name donnie">14</div>
            <div className="value-name consi correct">22</div>
          </div>
        </li>
      </ul>
    </div>
  );
};
