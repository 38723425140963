import React, { useEffect } from "react";
import cx from "classnames";

export interface PageProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
}

export const Page: React.FunctionComponent<PageProps> = ({
  children,
  className,
  title,
}) => {
  useEffect(() => {
    if (!title) {
      document.title = "Average Productions";
      return;
    }
    document.title = `${title} | Average Productions`;
  }, [title]);

  return <main className={cx("page", className)}>{children}</main>;
};
