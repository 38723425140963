import React from "react";
import { Triangle } from "../Backgrounds/Triangle";
import "./Top.scss";

export const Top = () => (
  <div className="top">
    <div className="top-text">
      <Triangle>
        <span className="lovelo">
          Average
          <span className="subtitle">productions</span>
        </span>
      </Triangle>
    </div>
    <h1>
      Average web design
      <br />
      since 1789
    </h1>
  </div>
);
