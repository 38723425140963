import React, { PureComponent } from "react";
import cx from "classnames";
import AnimUtils from "../../AnimUtils";
import "./Button.scss";
import { Status, InputStatus } from "../InputDataTypes";

export interface StatusButtonProps {
  children: React.ReactNode;
  className?: string;
  data?: any;
  onClick: (data: any) => void;
  inputStatus: InputStatus;
  block?: boolean;
  blockUntilStatusUpdate?: boolean;
  flip?: boolean;
  action?: boolean;
  placeholder?: string | React.ReactNode;
}

interface ButtonState {
  inputStatus: InputStatus;
  rotate: number;
  trigger: boolean;
  spinnerAnimReady: boolean;
  placeholder: string | React.ReactNode;
  focus: boolean;
  hovered: boolean;
}

export class StatusButton extends PureComponent<
  StatusButtonProps,
  ButtonState
> {
  static defaultProps = {
    data: {},
    block: false,
    blockUntilStatusUpdate: false,
    flip: false,
    inputStatus: {
      status: Status.DEFAULT,
    },
    ghost: false,
  };

  queue: React.ReactNode[];
  isAnimating: boolean;
  resetTimer: number | undefined;
  aborted: boolean;

  constructor(props: StatusButtonProps) {
    super(props);
    this.state = {
      inputStatus: props.inputStatus,
      rotate: 0,
      trigger: true,
      spinnerAnimReady: true,
      placeholder: props.placeholder || props.children,
      focus: false,
      hovered: false,
    };
    this.isAnimating = false;
    this.queue = [this.getButton(props)];
    this.resetTimer = undefined;
    this.aborted = false;
  }

  componentWillUnmount() {
    this.aborted = true;
    window.clearTimeout(this.resetTimer);
  }

  getButton(props: StatusButtonProps) {
    const { inputStatus } = props;
    if (inputStatus.status) {
      return (
        <div className={`button-${inputStatus.status} button-surface`}>
          {inputStatus.message || props.children}
        </div>
      );
    }

    return null;
  }

  componentDidUpdate() {
    if (this.props.flip) {
      return;
    }
    if (this.isAnimating) {
      return;
    }
    if (this.props.inputStatus.status === this.state.inputStatus.status) {
      return;
    }
    this.isAnimating = true;
    // this.queue.push(this.getButton(this.props));
    this.animate()
      .then(() => {
        this.isAnimating = false;
        this.setState({
          focus: false,
          trigger: !this.state.trigger,
        });
      })
      .catch(() => {});
  }

  onClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.preventDefault();
    if (this.isAnimating) {
      return;
    }
    if (this.props.flip) {
      this.isAnimating = true;
      this.props.onClick(this.props.data);
      this.animate()
        .then(() => {
          this.isAnimating = false;
          this.resetTimer = window.setTimeout(() => {
            this.setState({
              focus: false,
            });
          }, 100);
        })
        .catch(() => {});
    } else if (this.props.inputStatus.status === Status.DEFAULT) {
      this.props.onClick(this.props.data);
    }
  };

  onFocus = () => this.setState({ focus: true });
  onBlur = () => this.setState({ focus: false });
  onMouseEnter = () => this.setState({ hovered: true });
  onMouseOut = () => this.setState({ hovered: false });

  animate() {
    const self = this;

    return new Promise<void>((resolve, reject) => {
      const startRotate = 0;
      const endRotate = 180;
      let start = 0;
      let end = 0;
      let stop = false;
      let diff = 0;
      let timeOffset = 0;
      let rotate = 0;
      let spinnerAnimReady = this.state.spinnerAnimReady;
      let hasRotated = false;
      let prevNow = 0;

      function draw(now: number) {
        const currentStatus = self.props.inputStatus.status;
        let endStatus = currentStatus || Status.DEFAULT;

        if (self.aborted) {
          reject();
          return;
        }

        if (stop) {
          self.queue = [self.queue[1]];
          self.setState(
            {
              rotate: 0,
              spinnerAnimReady: true,
            },
            resolve
          );
          return;
        }

        if (now >= end) {
          stop = true;
        }

        diff = now - start;
        timeOffset = AnimUtils.inOutQuad(diff / AnimUtils.DURATION);
        rotate = startRotate + (endRotate - startRotate) * timeOffset;

        // 1. Status button
        if (!self.props.flip) {
          if (hasRotated) {
            self.setState({
              rotate,
              spinnerAnimReady: false,
            });
          } else if (rotate > 90) {
            hasRotated = true;
            self.queue.push(self.getButton(self.props));
            self.setState({
              rotate,
              spinnerAnimReady: false,
              inputStatus: {
                status: endStatus,
                message: self.props.inputStatus.message,
              },
            });
          } else {
            self.setState({
              rotate,
              spinnerAnimReady: true,
            });
          }

          requestAnimationFrame(draw);
          return;
        }

        // 2. Flip button without wait
        if (!self.props.blockUntilStatusUpdate) {
          if (rotate > 90 && !hasRotated) {
            self.queue.push(self.getButton(self.props));
            endStatus = currentStatus || Status.DEFAULT;
            hasRotated = true;
            spinnerAnimReady = false;
          }
          self.setState({
            rotate,
            spinnerAnimReady,
            inputStatus: {
              status: endStatus,
              message: self.props.inputStatus.message,
            },
          });
          requestAnimationFrame(draw);
          return;
        }

        // 3. Flip button with wait
        // 3a. Wait
        if (hasRotated && currentStatus === self.state.inputStatus.status) {
          const timeDiff = now - prevNow;
          start += timeDiff;
          end += timeDiff;
          prevNow = now;
          requestAnimationFrame(draw);
          return;
        } else if (hasRotated && !self.queue[1]) {
          self.queue.push(self.getButton(self.props));
          endStatus = currentStatus || Status.DEFAULT;
        }

        // 3b. Rotate
        if (rotate > 90 && !hasRotated) {
          hasRotated = true;
          spinnerAnimReady = false;
          prevNow = now;
          self.setState({
            rotate,
            spinnerAnimReady,
            inputStatus: {
              status: endStatus,
              message: self.props.inputStatus.message,
            },
          });
        } else {
          self.setState({
            rotate,
            spinnerAnimReady,
          });
        }
        requestAnimationFrame(draw);
      }

      function startAnim(timeStamp: number) {
        start = timeStamp;
        end = start + AnimUtils.DURATION;
        draw(timeStamp);
      }

      requestAnimationFrame(startAnim);
    });
  }

  render() {
    const { className, block, action } = this.props;
    const buttonProps: { [k: string]: number } = {};
    const {
      rotate,
      spinnerAnimReady,
      placeholder,
      focus,
      hovered,
      inputStatus,
    } = this.state;
    let elem = null;

    if (rotate <= 90) {
      // Else remove chrome rendering artifact
      elem = this.queue[0];
    }

    const classes = cx(
      "status-button",
      "button",
      className,
      inputStatus.status.toLowerCase(),
      {
        block,
        action,
        "spinner-anim-ready": spinnerAnimReady,
        "has-focus": focus,
        "is-hovered": hovered,
      }
    );

    const transform = action
      ? `rotateY(${rotate}deg)`
      : `rotateX(${rotate}deg)`;

    return (
      <button
        className={classes}
        onClick={this.onClick}
        {...buttonProps}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onMouseEnter={this.onMouseEnter}
        onMouseOut={this.onMouseOut}
      >
        <div className="relative">
          <div className="status-button-placeholder">{placeholder}</div>
          <div className="button-flipper" style={{ transform }}>
            <div className="button-front">{elem}</div>
            <div className="button-back">{this.queue[1]}</div>
          </div>
        </div>
      </button>
    );
  }
}
