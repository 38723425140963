import React from "react";
import cx from "classnames";
import { Player } from "./Games";

interface Game {
  time: string;
  game: string;
  isSweden?: boolean;
  channel: string;
}

interface Props {
  game: Game;
  dateString: string;
  tips: string[];
  result: string | null;
}

function getGameStatus(tips: string, result: string | null) {
  if (!result) {
    return "neutral";
  }

  if (tips === result) {
    return "correct";
  }

  return "incorrect";
}

export const Row: React.FunctionComponent<Props> = ({
  game,
  dateString,
  tips,
  result,
}) => {
  if (!tips) {
    return null;
  }

  return (
    <li>
      <div className="date-and-name">
        <div className="game-date">
          {dateString}, {game.time}
        </div>
        <div className="game-name">
          {game.isSweden ? <b>{game.game}</b> : game.game}
        </div>
      </div>
      <div
        className={cx(
          "game-cell",
          "stumpan",
          getGameStatus(tips[Player.STUMPAN], result)
        )}
      >
        {tips[Player.STUMPAN]}
      </div>
      <div
        className={cx(
          "game-cell",
          "knox",
          getGameStatus(tips[Player.KNOX], result)
        )}
      >
        {tips[Player.KNOX]}
      </div>
      <div
        className={cx(
          "game-cell",
          "zege",
          getGameStatus(tips[Player.ZEGE], result)
        )}
      >
        {tips[Player.ZEGE]}
      </div>
      <div
        className={cx(
          "game-cell",
          "kakel",
          getGameStatus(tips[Player.KAKEL], result)
        )}
      >
        {tips[Player.KAKEL]}
      </div>
      <div
        className={cx(
          "game-cell",
          "donnie",
          getGameStatus(tips[Player.DONNIE], result)
        )}
      >
        {tips[Player.DONNIE]}
      </div>
      <div
        className={cx(
          "game-cell",
          "consi",
          getGameStatus(tips[Player.CONSI], result)
        )}
      >
        {tips[Player.CONSI]}
      </div>
    </li>
  );
};
