export enum Theme {
  LIGHT = "light",
  DARK = "dark",
}

const NAME = "average";

const defaults = {
  showIntro: true,
  theme: Theme.LIGHT,
  showTipsTable: true,
};

const storageData = window.localStorage.getItem(NAME);

interface IAverageItem {
  [key: string]: any;
}

let average: IAverageItem = {};

if (storageData) {
  average = {
    ...defaults,
    ...JSON.parse(storageData),
  };
} else {
  average = { ...defaults };
}

export default class Store {
  static getValue(key: string): any {
    return average[key];
  }
  static setValue(key: string, value: any) {
    average[key] = value;
    try {
      window.localStorage.setItem(NAME, JSON.stringify(average));
    } catch (err) {
      //Do not log
    }
  }
  static getStorage() {
    return average;
  }
}
