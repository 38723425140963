import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { viewState, ViewState } from "../state/uiState";
import { Page } from "./Page";

export interface Props {
  children: React.ReactNode;
  className?: string;
  title?: string;
}

export const PageStripped: React.FunctionComponent<Props> = (props) => {
  const setViewState = useSetRecoilState(viewState);

  useEffect(() => {
    setViewState(ViewState.STRIPPED);

    return () => {
      setViewState(ViewState.DEFAULT);
    };
  }, [setViewState]);

  return <Page {...props} />;
};
