import React, { useCallback } from "react";
import "./Footer.scss";
import { Logo } from "../Logo/Logo";
import { useSetRecoilState } from "recoil";
import { navigateState } from "../../state/uiState";

export function Footer() {
  const setNavigateState = useSetRecoilState(navigateState);

  const onNavigate = useCallback(
    (to) => {
      setNavigateState(to);
    },
    [setNavigateState]
  );

  const toHome = useCallback(() => onNavigate("/"), [onNavigate]);

  return (
    <footer>
      <div className="footer-bg">
        <svg
          preserveAspectRatio="none"
          viewBox="0 0 100 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0 L100 15 L0 20 Z" fill="#fff" />

          <path d="M0 20 L100 0 L100 20 Z" fill="#fff" />

          <path
            vectorEffect="non-scaling-stroke"
            d="M0 0 L100 15"
            stroke="#bdc5cd"
            fill="none"
          />

          <path
            vectorEffect="non-scaling-stroke"
            d="M0 20 L100 0"
            stroke="#bdc5cd"
            fill="none"
          />
        </svg>
      </div>
      <div className="footer-inner">
        <button aria-label="Home" onClick={toHome}>
          <Logo />
        </button>
        <div className="disclaimer">&copy; 2022 &#126; Average productions</div>
        <a href="mailto:contact@average.productions">@ contact</a>
      </div>
    </footer>
  );

  // return (
  //   <footer>
  //     <svg preserveAspectRatio="none" viewBox="0 0 100 20">
  //       <defs>
  //         <linearGradient id="footer-grad-first" gradientTransform="rotate(90)">
  //         <stop offset="5%" stop-color="blue" />
  //           <stop offset="45%" stop-color="#f8faff"/>
  //         </linearGradient>

  //         <linearGradient id="footer-grad-second" gradientTransform="rotate(70)">
  //           <stop offset="15%" stop-color="#3b4753" />
  //           <stop offset="95%" stop-color="#565e67"/>
  //         </linearGradient>

  //         <linearGradient  id="footer-grad-third" gradientTransform="rotate(70)">
  //           <stop offset="35%" stop-color="#2e3740" />
  //           <stop offset="95%" stop-color="#47525d"/>
  //         </linearGradient>
  //       </defs>

  //       <path d="M0 0 L100 10 L100 20 L0 20 Z" fill="url('#footer-grad-second')"/>
  //       <path d="M0 10 L100 3 L100 20 L0 20 Z" fill="url('#footer-grad-third')"/>
  //       {/* <path d="M0 10 L100 6 L100 20 L0 20 Z" fill="url('#footer-grad-second')" fillOpacity="0.2"/> */}
  //     </svg>
  //   </footer>
  // );
}

// #627282
