import React from "react";
import "./NavAnim.scss";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AnimUtils from "../AnimUtils";
import { useRecoilState } from "recoil";
import { navigateState } from "../../state/uiState";

interface Props extends RouteComponentProps {
  setNavigate: (link: string) => void;
  navigate: string;
}

interface State {
  width: number;
  offset: number;
}

interface AnimProps {
  offsetStart: number;
  offsetEnd: number;
}

const defaultValues = {
  width: 0,
  offset: 0,
};

class Animation extends React.PureComponent<Props, State> {
  isAnimating: boolean = false;

  constructor(props: Props) {
    super(props);
    this.state = { ...defaultValues };
  }

  componentDidUpdate() {
    if (this.isAnimating) {
      return;
    }

    if (this.props.navigate) {
      this.isAnimating = true;

      const innerWidth = window.innerWidth;
      const max = Math.max(innerWidth, window.innerHeight);

      this.setState(
        {
          width: max,
          offset: 0,
        },
        () => {
          this.run({
            offsetStart: 0,
            offsetEnd: innerWidth / 2,
          }).then(() => {
            if (this.props.navigate) {
              this.props.history.push(this.props.navigate);
            }
            setTimeout(() => {
              this.run({
                offsetStart: this.state.offset,
                offsetEnd: 0,
              }).then(() => {
                this.props.setNavigate("");
                this.setState({ ...defaultValues }, () => {
                  this.isAnimating = false;
                });
              });
            }, 300);
          });
        }
      );
    }
  }

  run(props: AnimProps) {
    return new Promise<void>((resolve, reject) => {
      const { offsetStart, offsetEnd } = props;
      const self = this;
      let start = 0;
      let end = 0;
      let stop = false;
      let diff = 0;
      let progress = 0;
      let offset = offsetStart;

      function draw(now: number) {
        if (stop) {
          self.setState(
            {
              offset: offsetEnd,
            },
            () => {
              resolve();
            }
          );
          return;
        }

        if (now >= end) {
          stop = true;
          requestAnimationFrame(draw);
          return;
        }
        diff = now - start;
        progress = AnimUtils.inOutQuad(diff / AnimUtils.DURATION);
        offset = offsetStart + (offsetEnd - offsetStart) * progress;

        self.setState({
          offset,
        });

        requestAnimationFrame(draw);
      }

      function startAnim(timeStamp: number) {
        start = timeStamp;
        end = start + AnimUtils.DURATION;
        draw(timeStamp);
      }

      requestAnimationFrame(startAnim);
    });
  }

  render() {
    const { width, offset } = this.state;

    return (
      <div className="nav-anim">
        <div
          style={{ width, transform: `translateX(${offset - width}px)` }}
          className="nav-anim-left"
        >
          <div />
        </div>
        <div
          style={{
            width: `${width}px`,
            right: `-${width}px`,
            transform: `translateX(-${offset}px)`,
          }}
          className="nav-anim-right"
        >
          <div />
        </div>
      </div>
    );
  }
}

const AnimationWrapper: React.FunctionComponent<RouteComponentProps> = (
  props
) => {
  const [navigate, setNavigate] = useRecoilState(navigateState);
  return <Animation {...props} navigate={navigate} setNavigate={setNavigate} />;
};

export const NavAnim = withRouter(AnimationWrapper);

// export const NavAnim = withRouter(withAverageContext(Animation));
