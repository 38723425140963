import React, { PureComponent } from "react";
import cx from "classnames";

import "./Testamonials.scss";

const quotes = [
  {
    text: "We didn't really have any expectations. Those were fulfilled.",
    author: "Imagined customer",
  },
  {
    text:
      "This studio is a paragon of averageness. Now, where's that guillotine?",
    author: "Maximilien Robespierre",
  },
  {
    text: "I'm just, wow, the sheer mediocrity of it all. Unheard of.",
    author: "Louis XVI of France",
  },
  {
    text: "Raw, untamed, cursed averageness. Like English tea.",
    author: "Charles Maurice de Talleyrand",
  },
];

function getRandomInt(initialMin: number, initialMax: number) {
  const min = Math.ceil(initialMin);
  const max = Math.floor(initialMax);
  return Math.floor(Math.random() * (max - min)) + min;
}

class Testamonials extends PureComponent {
  timer: NodeJS.Timeout | null = null;
  firstFigure = React.createRef<HTMLElement>();
  secondFigure = React.createRef<HTMLElement>();
  thirdFigure = React.createRef<HTMLElement>();
  fourthFigure = React.createRef<HTMLElement>();

  state = {
    activeIndex: getRandomInt(0, 2),
    prevIndex: null,
  };

  onAnimationEnd = (ev: AnimationEvent) => {
    if (ev.animationName !== "blurOut") {
      return;
    }

    this.setState({
      activeIndex: (this.state.activeIndex + 1) % 4,
      prevIndex: null,
    });

    this.timer = setTimeout(() => {
      this.setState({
        prevIndex: this.state.activeIndex,
      });
    }, 4000);
  };

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.firstFigure?.current?.removeEventListener(
      "animationend",
      this.onAnimationEnd,
      false
    );

    this.secondFigure?.current?.removeEventListener(
      "animationend",
      this.onAnimationEnd,
      false
    );

    this.thirdFigure?.current?.removeEventListener(
      "animationend",
      this.onAnimationEnd,
      false
    );

    this.fourthFigure?.current?.removeEventListener(
      "animationend",
      this.onAnimationEnd,
      false
    );
  }

  componentDidMount() {
    this.firstFigure?.current?.addEventListener(
      "animationend",
      this.onAnimationEnd,
      false
    );

    this.secondFigure?.current?.addEventListener(
      "animationend",
      this.onAnimationEnd,
      false
    );

    this.thirdFigure?.current?.addEventListener(
      "animationend",
      this.onAnimationEnd,
      false
    );

    this.fourthFigure?.current?.addEventListener(
      "animationend",
      this.onAnimationEnd,
      false
    );

    this.timer = setTimeout(() => {
      this.setState({
        prevIndex: this.state.activeIndex,
      });
    }, 6000);
  }

  render() {
    const { activeIndex, prevIndex } = this.state;

    return (
      <div className="testamonials-wrapper">
        <div className="anim-parent">
          <figure
            ref={this.firstFigure}
            className={cx({
              "fade-in": activeIndex === 0,
              "fade-out": prevIndex === 0,
            })}
          >
            <blockquote>{quotes[0].text}</blockquote>
            <figcaption>
              <cite>- {quotes[0].author}</cite>
            </figcaption>
          </figure>

          <figure
            ref={this.secondFigure}
            className={cx({
              "fade-in": activeIndex === 1,
              "fade-out": prevIndex === 1,
            })}
          >
            <blockquote>{quotes[1].text}</blockquote>
            <figcaption>
              <cite>- {quotes[1].author}</cite>
            </figcaption>
          </figure>

          <figure
            ref={this.thirdFigure}
            className={cx({
              "fade-in": activeIndex === 2,
              "fade-out": prevIndex === 2,
            })}
          >
            <blockquote>{quotes[2].text}</blockquote>
            <figcaption>
              <cite>- {quotes[2].author}</cite>
            </figcaption>
          </figure>

          <figure
            ref={this.fourthFigure}
            className={cx({
              "fade-in": activeIndex === 3,
              "fade-out": prevIndex === 3,
            })}
          >
            <blockquote>{quotes[3].text}</blockquote>
            <figcaption>
              <cite>- {quotes[3].author}</cite>
            </figcaption>
          </figure>
        </div>
      </div>
    );
  }
}

export default Testamonials;
