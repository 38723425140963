import React from 'react';

import './Attributes.scss';


export function Attributes() {

  return (
    <div className="attributes">
      <dl>
        <dt>Javascript</dt>
        <dd>
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span />
          <span />
          <span />
          <span />
          <span />
        </dd>
        <dt>Roman emperors</dt>
        <dd>
          <span className="active"/>
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span />
          <span />
          <span />
          <span />
          <span />
        </dd>
        <dt>React</dt>
        <dd>
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span />
          <span />
          <span />
          <span />
          <span />
        </dd>
        <dt>Cosmology</dt>
        <dd>
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span />
          <span />
          <span />
          <span />
          <span />
        </dd>
        <dt>User experience</dt>
        <dd>
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span />
          <span />
          <span />
          <span />
          <span />
        </dd>
        <dt>Averageness</dt>
        <dd>
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span className="active" />
        </dd>
        <dt>D3.js</dt>
        <dd>
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span className="active" />
          <span />
          <span />
          <span />
          <span />
          <span />
        </dd>
      </dl>
    </div>
  );
}