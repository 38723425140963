import React, { useState, ReactNode, useEffect, useCallback } from "react";
import { HeightAnim } from "../Interactions/HeightAnim/HeightAnim";
import { Loading } from "./Loading";
import { Error } from "./Error";
import "./Lazy.scss";

interface Props {
  load: any;
  name: string;
}

export const Lazy: React.FC<Props> = ({ load, name }) => {
  const [elem, setElem] = useState<ReactNode>(
    window.average.loaded[name] || <Loading />
  );

  const initialLoad = useCallback(() => {
    load()
      .then((data: any) => {
        const C = data[name];
        window.average.loaded[name] = <C />;
        setElem(<C />);
      })
      .catch((err: any) => {
        const retry = () => {
          setElem(<Loading />);
          setTimeout(() => {
            initialLoad();
          }, 1800);
        };
        setElem(<Error retry={retry} />);
      });
  }, [load, name]);

  useEffect(() => {
    if (window.average.loaded[name]) {
      return;
    }

    initialLoad();
  }, [initialLoad, name]);

  return <HeightAnim>{elem}</HeightAnim>;
};
