export interface InputAttributes {
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  tabIndex?: number;
}

export enum InputType {
  INPUT = "input",
  SELECT = "select",
  CHECKBOX = "checkbox",
  RADIOBUTTON = "radio",
}

export enum Status {
  SUCCESS = "success",
  ERROR = "error",
  DEFAULT = "default",
  PENDING = "pending",
  DISABLED = "disabled",
  EMPTY = "empty",
}

export enum ExtendedStatus {
  FOCUS = "focus",
}

export interface InputStatus {
  status: Status;
  message?: string | React.ReactNode;
}

export interface StateInputStatus {
  status: Status | ExtendedStatus;
  message?: string | React.ReactNode;
}

export enum ChangeType {
  Change,
  Blur,
  Clear,
  Focus,
}

export interface ChangeEvent {
  value: string;
  type: ChangeType;
  id: string;
  index?: number;
  checked?: boolean;
}

export const defaultAttributes = {
  autoComplete: "",
  autoFocus: false,
  readOnly: false,
  required: true,
  tabIndex: 0,
  disabled: false,
};

export interface Colors {
  [key: string]: string;
}

export interface Option {
  value: string;
  label: string | React.ReactNode;
  status?: Status;
}

export function getColorObject(colors: Colors, inputStatus: InputStatus) {
  if (inputStatus.status === Status.DEFAULT) {
    return {
      color: colors[inputStatus.status],
      borderColor: colors["default_border"],
    };
  }
  if (inputStatus.status === Status.DISABLED) {
    return {
      color: colors[inputStatus.status],
      borderColor: colors["disabled_border"],
    };
  }
  if (inputStatus.status === Status.PENDING) {
    return {
      color: colors[inputStatus.status],
      borderColor: colors["pending_border"],
    };
  }
  return {
    color: colors[inputStatus.status],
    borderColor: colors[inputStatus.status],
  };
}
