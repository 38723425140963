import React from 'react';
import './Logo.scss';

export function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 300"
      className="logo"
    >
      <path d="M2 232 L100 0 L198 232" />
      <path d="M2 232 L100 83 L198 232" />
      <path d="M2 232 L100 166 L198 232" />
      <path d="M2 232 L100 298 L198 232" />
    </svg>
  );
}