import React from "react";
import "./Quiz.module.scss";
import { Page } from "../Page";
import { Question } from "./Question";

export enum Subject {
  SCIENCE = "Vetenskap",
  SPORT = "Sport",
  HISTORY = "Historia",
  GEOGRAPHY = "Geografi",
}

export const Quiz: React.FunctionComponent = () => {
  return (
    <Page title="Quiz" className="quiz">
      <section>
        <article>
          <Question
            numberOfQuestion={1}
            subQuestions={3}
            subject={Subject.SCIENCE}
          />

          <Question
            numberOfQuestion={2}
            subQuestions={[
              "Rom",
              "New York",
              "Tokyo",
              "Santiago",
              "Rio de Janeiro",
              "Kapstaden",
              "Sidney",
              "Peking",
              "Mekka",
              "Moskva",
              "Sankt Petersburg",
              "Wien",
              "Istanbul",
              "San Francisco",
            ]}
            subject={Subject.GEOGRAPHY}
          />
        </article>
      </section>
    </Page>
  );
};
