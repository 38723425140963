import React from "react";
import { Footer } from "../../components/Footer/Footer";
import { PageStripped } from "../PageStripped";
import { Games } from "./Games";
import { Posts } from "./Posts";
import "./Tips.scss";

export const Tips: React.FunctionComponent = () => (
  <PageStripped className="tips" title="Tips">
    <section>
      <article>
        <div className="tips-wrapper">
          <Games />
          <Posts />
        </div>
        <Footer />
      </article>
    </section>
  </PageStripped>
);
