import React, { useCallback } from "react";
import { Logo } from "../Logo/Logo";
import { FlipButton } from "../Interactions/Button/FlipButton";
import { useSetRecoilState } from "recoil";
import { navigateState } from "../../state/uiState";
import "./Header.scss";

export function Header() {
  const setNavigateState = useSetRecoilState(navigateState);

  const onNavigate = useCallback(
    (to) => {
      setNavigateState(to);
    },
    [setNavigateState]
  );

  const toHome = useCallback(() => onNavigate("/"), [onNavigate]);
  const toComponents = useCallback(() => onNavigate("/components"), [
    onNavigate,
  ]);

  return (
    <header>
      <section>
        <article>
          <div className="logo-wrapper">
            <button aria-label="Home" onClick={toHome}>
              <Logo />
            </button>
          </div>
          <nav>
            <ul>
              <li>
                <FlipButton onClick={toComponents}>Components</FlipButton>
              </li>
            </ul>
          </nav>
        </article>
      </section>
    </header>
  );
}
