import { atom } from "recoil";
import Store from "../Store";

export enum ViewState {
  STRIPPED = "stripped",
  DEFAULT = "",
}

export const showIntroState = atom({
  key: "showIntro",
  default: Store.getValue("showIntro"),
});

export const introRunningState = atom({
  key: "introRunning",
  default: false,
});

export const themeState = atom({
  key: "theme",
  default: Store.getValue("theme"),
});

export const navigateState = atom({
  key: "navigate",
  default: "",
});

export const viewState = atom<ViewState>({
  key: "viewState",
  default: ViewState.DEFAULT,
});
