import React, { useCallback } from "react";
import { Page } from "./Page";
import { Bubbles } from "../components/Logo/Bubbles";
import { Footer } from "../components/Footer/Footer";
import { useSetRecoilState } from "recoil";
import { navigateState } from "../state/uiState";
import "./NoMatch.scss";

export const NoMatch: React.FunctionComponent = () => {
  const setNavigateState = useSetRecoilState(navigateState);

  const onNavigate = useCallback(
    (to) => {
      setNavigateState(to);
    },
    [setNavigateState]
  );

  const toHome = useCallback(
    (ev) => {
      ev.preventDefault();
      onNavigate("/");
    },
    [onNavigate]
  );

  return (
    <Page className="no-match" title="404">
      <section>
        <article>
          <div className="not-found-ingress">
            <h1>Missing averageness!</h1>
            <p>
              The page is gone. That's all there is to it.
              <br />
              <a href="/" onClick={toHome}>
                Get me out of here.
              </a>
            </p>
          </div>
          <div className="not-found-logo">
            <Bubbles />
          </div>
        </article>
      </section>

      <Footer />
    </Page>
  );
};
