import React from "react";
import "./Works.scss";
import { Image } from "../Images/Image";

export const Works: React.FunctionComponent = () => {
  return (
    <div className="works-images">
      <a
        href="https://interactive-history.app"
        target="_blank"
        rel="noreferrer"
      >
        <div className="works-item">
          <h4>Interactive History</h4>
          <Image
            src="/images/ih_thumbnail_1600x1200.jpg"
            ratio={1600 / 1200}
            alt="Image of Interactive History website"
          />
        </div>
      </a>

      <a href="https://dev-poetry.com" target="_blank" rel="noreferrer">
        <div className="works-item">
          <h4>Dev Poetry</h4>
          <Image
            src="/images/dev-poetry_thumbnail_1600x1201.jpg"
            ratio={1600 / 1201}
            alt="Image of Dev Poetry website"
          />
        </div>
      </a>
    </div>
  );
};
