import React from "react";
import { FlipButton } from "../Interactions/Button/FlipButton";

interface Props {
  retry: () => void;
}

export function Error({ retry }: Props) {
  return (
    <div className="lazy-error">
      <p>
        <b>Uugh!</b> Something went wrong.
      </p>
      <FlipButton block onClick={retry}>
        Try again
      </FlipButton>
    </div>
  );
}
